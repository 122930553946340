#featured {

  border-top: 1px solid $medium-gray;
  border-bottom: 4px solid $action-color;
  box-shadow: 0em -0.1em 31px -11px black;
  margin: 1em auto 0px;

  section > ul.menu {
    display: flex;
    flex-flow: row wrap;
    align-content: stretch;

    > li {
      text-align: left;
      position: relative;
      border-left: 1px solid $light-gray;

      &.active-trail {
        box-shadow: 0px -3px 0 1px $action-color inset;
      }
      @include media($feature-menu-snap-down-large) {
      &.expanded:hover:after {
          @include triangle(50px 13px, white, up);
          content: "";
          position: absolute;
          left: 10%;
          bottom: -37%;
          z-index: 1;
        }
      }

      &:last-child {
        border-right: 1px solid $light-gray;
      }

      &:before {
        position: absolute;
        content: "";
        left: 15px;
        top: 13px;
      }

      > a {
        width: 100%;
        line-height: 4.3em;
        display: inline-block;
        color: $medium-gray;
        padding: 0 1em 0 4.4em;
        white-space: nowrap;
      }

      &.expanded.last  {
        ul.menu {
          width: 17em;
          left: -2.5em;
        }
      }

      ul.menu {
        display: none;
        position: absolute;
        border: 0px solid white;
        background: transparent;
        margin-top: 0;
        border-radius: 2px;
        z-index: 1;
        padding: 1.6em 0 0em 0;

        li {
          float: left;
          width: 100%;
          text-align: left;
          padding: 0.5em 1em;
          background: white;
          &:hover {
            background-color: lighten($action-color, 20%);
          }

          a {
            font-weight: bold;
            font-size: 0.85em;
            padding: 0.25em;
            color: $action-color;
          }
        }
      }

      &:hover {
        ul.menu {
          @include media($feature-menu-snap-down-large) {
            display: block;
          }
        }
      }

      &.featured {
        background-repeat: no-repeat;
        background-position: 1em center;
        &.cleaning-services {
          background-image: url('/sites/all/themes/lowbudgetcleaning/images/icon-cleaning.png');
        }
        &.hygiene {
          background-image: url('/sites/all/themes/lowbudgetcleaning/images/icon-hygiene.png');
        }
        &.tea-coffee {
          background-image: url('/sites/all/themes/lowbudgetcleaning/images/icon-tea-coffee.png');
        }
        &.services {
          background-image: url('/sites/all/themes/lowbudgetcleaning/images/icon-add-on.png');
        }
      }
    }

    > li {
      flex: 1 0 100%;

      @include media($feature-menu-snap-down-small) {
        flex: 1 1 50%;
      }

      @include media($feature-menu-snap-down-large) {
        flex: 1 0 auto;
      }
    }
  }
}