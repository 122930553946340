#site-header {
  @include outer-container;
  .outer-wrapper {
    @include row;

    //Logo - page.tpl.php
    #logo {
      @include span-columns(1 of 1);
      text-align: center;
      @include media($large-screen-up, 8) {
        text-align: left;
        @include span-columns(3 of 8);
      }

      @include media($full-screen-up, 12) {
        text-align: left;
        @include span-columns(3 of 12);
      }
    }

    //Main Menu (top)
    section.block-system {
      @include span-columns(1 of 1);

      @include media($large-screen-up, 8) {
        text-align: left;
        @include span-columns(8 of 8);
      }

      @include media($full-screen-up, 12) {
        text-align: left;
        @include span-columns(6.5 of 12);

        @include shift-in-context(-8.8);
      }


      .menu {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        @include media($header-menu-snap) {
          margin-top: 0em;
        }

        @include media($full-screen-up) {
          margin-top: 2.8em;
          flex-flow: row nowrap;
        }

        li {
          @include media($medium-screen-up) {
            flex: 1 2 auto;
            padding: 1em 0.5em 0em;
            text-decoration: underline;
          }

          @include media($header-menu-snap) {
            flex: 1 1 auto;
            text-decoration: none;
            &:not(:last-child):after {
              content: "/";
              margin-left: 25%;
              color: $action-color;
            }
          }

          flex: 1 2 auto;

          text-align: center;
          align-self: flex-end;
          a {
            color: $medium-gray;
            white-space: nowrap;
          }
        }
      }
    }

    // Contact Block (top)
    section.block-block {
      @include span-columns(1 of 1);

      @include media($large-screen-up, 8) {
        text-align: left;
        @include span-columns(2 of 8);
        @include shift-in-context(3);
        @include omega();
      }

      @include media($full-screen-up, 12) {
        text-align: right;
        @include span-columns(2.5 of 12);
        @include shift-in-context(6.5);
        @include omega();
      }
    }
  }
}