// Typography
$base-font-family: "Lato", $helvetica;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.4;
$heading-line-height: 1.4;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #477dca;
$dark-gray: #333;
$medium-gray: #666;
$light-gray: #ddd;

$orange: #f27330;

// Font Colors
$base-background-color: #fff;
$base-font-color: $dark-gray;
$action-color: $orange;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

$visual-grid: false !global;
$visual-grid-index: front !global;
$visual-grid-color: #F00 !global;