body {
  @include font-feature-settings("kern", "liga", "pnum");
  -webkit-font-smoothing: antialiased;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Fjalla One;
  font-size: $base-font-size;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

h1, h2 {
  font-size: 1.5em;
  text-transform: uppercase;
  font-family: Fjalla One;
  color: $medium-gray;
}

h3 {
  font-size: 1.1em;
  color: $action-color;
  text-align: center;
}

#sidebar-second h2 {
  text-align: center;
}

blockquote {
  color: $action-color;
  font-style: italic;
  padding: 0.50em;
  font-size: 1.06em;
  margin: 0;
  line-height: $heading-line-height;
}

p {
  margin: 0;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color 0.1s linear;

  &:active,
  &:focus,
  &:hover {
    color: darken($action-color, 15%);
  }

  &:active,
  &:focus {
    outline: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img {
  margin: 0;
  max-width: 100%;
}

.orange-color{
 color: $action-color;
}

.our-reputation {
  font-size: 1.5em;
  display: inline-block;
  padding: 1em 0 0;
}

#content {
  ul {
    list-style: disc;
    padding-left: 2em;
    margin-bottom: 2em;
  }

  ol {
    list-style: decimal;
    padding-left: 1em;
    margin: 2em 0;
    li {
      padding-left: 1em;
    }
  }

  p:not(:last-child){
    padding: 0.5em 0
  }
}
