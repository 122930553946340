#site-footer {
  .medium-gray {
    color: $medium-gray;
    font-weight: 400;
  }
  section {
    @include outer-container;
  }
  max-height: 290px;
  @include background-image(linear-gradient(top, rgba(216, 215, 215, 1) 0%, rgba(226, 225, 225, 1) 0%, rgba(227, 226, 226, 1) 2%, rgba(232, 231, 231, 1) 24%, rgba(250, 250, 250, 1) 75%, rgba(255, 255, 255, 1) 100%));
  padding: ($base-spacing * 2) $gutter;
  width: 100%;

  .footer-top {
    margin-bottom: 1em;
    @include row;

    h2 {
      color: $action-color;
      font-size: 1em;
      font-variant: small-caps;
      text-transform: uppercase;
    }

    li {
      list-style-image: url("/sites/all/themes/lowbudgetcleaning/images/bullet-grid-arrow.png");
      margin-left: 1em;
      a {
        color: $medium-gray;
        line-height: 1.1em;
      }
    }

    // Contact Information & Clickable Contact Block.
    .block-block {
      @include media($medium-screen-up, 4) {
        @include span-columns(4 of 4);
        @include shift-in-context(0);
        @include omega();
        text-align: center;

        .credits {
          display: block;
          font-size: 0.8em;
          @include media($large-screen) {
            display: none;
          }
        }
      }

      @include media($large-screen-up, 8) {
        @include span-columns(4 of 8);
        @include shift-in-context(0);
        text-align: left;
      }

      #footer-menu-wrapper {
        float: left;
        clear: both;
        margin: 1em 0;
        font-size: 0.9em;
        p {
          margin: 0;
          line-height: 1.8em;
          display: inline-block;
          float: left;
          clear: both;
          width: 100%;
          padding: 0 1em 0 0;
          text-align: left;

          //Website credits.
          &.credits {
            display: none;
            @include media($large-screen) {
              display: block;
            }
          }
        }
        a {
          font-weight: bold;
        }
      }
      .contact-block {
        color: $action-color;

        float: none;
        cursor: pointer;
        width: 100%;
        line-height: 1em;

        > span {
          float: left;
          margin-right: 1em;
          text-align: center;
          width: 100%;
          line-height: 1.3;
          &.icon-phone {
            margin-top: -1.1em;
          }

          @include media($medium-screen-up) {
            width: auto;
            float: none;
          }

          @include media($large-screen-up) {

          }
        }
      }
    }

    // Quick Links
    .block-menu {
      font-size: 0.9em;

      @include media($medium-screen-up, 4) {
        @include span-columns(2 of 4);
        @include omega();
      }

      @include media($large-screen-up, 8) {
        @include span-columns(2 of 8);
      }
    }

    // Add-on Services
    .block-menu-block {
      font-size: 0.9em;

      @include media($medium-screen-up, 4) {
        @include span-columns(2 of 4);
      }

      @include media($large-screen-up, 8) {
        @include span-columns(2 of 8);
        @include omega();
      }
    }
  }
}
