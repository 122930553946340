body {
  margin: 0;
  padding: 0;

  main {
    @include outer-container;
    padding: 3em 0;
  }

  &.one-sidebar.sidebar-second {
    main {
      @include outer-container;
      width: 100%;
      padding: ($base-spacing * 0) $gutter ($base-spacing * 2);

      @include media($full-screen-snap-down) {
        padding: 0;
      }

      aside {
        &#sidebar-second {
          @include span-columns(1 of 1);

          @include media($large-screen-up, 8) {
            @include span-columns(2 of 8);
          }
        }
      }

      section#content {
        @include span-columns(9 of 12);
        margin: 1em auto 2em;

        @include media($medium-screen-up, 4) {
          @include span-columns(4 of 4);
        }

        @include media($large-screen-up, 8) {
          @include span-columns(6 of 8);
        }
      }
    }
  }
}
.outer-wrapper {
  @include outer-container;
}