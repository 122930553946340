#slider {
  overflow: hidden;
  position: relative;
  display: none;
  @include media($large-screen-up) {
    display: block;
  }
  .slick__image {
    margin: 0 auto;
    border-top: 1px solid white;
  }
  .slick__arrow {
    display: none;
  }

  ul.slick-dots {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    li {
      float: left;
      flex: 0 1 1em;
      button {
        font-size: 0.6em;
        color: $action-color;
        margin: 2em 0.5em;
        border-radius: 0;
      }

      &.slick-active button {
        color: $medium-gray;
        background-color: $medium-gray;
      }
    }
  }
}

.slick-media.media.media--image {
  text-align: center;

  img {
    margin: 0 auto;
  }
}

#slick-node-1-page-field-images-1 {
  position: relative;
  margin: 3em auto;
  nav.slick__arrow {
    position: static;
    top: 50%;
    transform: translateY(-50%);

    button {
      position: absolute;
      top: -38px;
      font-size: 0;

      &.slick-prev {
        left: 0;
        &:before {
          position: absolute;
          font-size: 15px;
          content: "<";
          padding: 7px;
          color: white;
          background: transparentize($action-color, 0.2);
          border-radius: 2px;
        }
      }

      &.slick-next {
        right: 23px;
        &:before {
          position: absolute;
          font-size: 15px;
          content: ">";
          padding: 7px;
          color: white;
          background: transparentize($action-color, 0.2);
          border-radius: 2px;
        }
      }
    }
  }
}