#sidebar-second {
 .menu-block-2 {
   ul.menu {
     display: flex;
     flex-flow: row wrap;
     justify-content: center;
     width: 100%;

     li {
       padding: 1%;
       margin-bottom: 3%;
       flex: 0 1 25%;

       @include media($large-screen-up) {
         height: 90px;
         margin-bottom: 20%;
         flex: 0 0 50%;
       }

       a {
         display: block;
         padding-top: 90%;
         background-repeat: no-repeat;
         background-position: top center;
         background-size: contain;
         font-size: 0.61em;
         text-align: center;
         text-transform: uppercase;
         margin-top: 6%;
         &:hover {
           text-decoration: underline;
         }
       }

       a.to-anchor-garden {
         background-image: url("/sites/all/themes/lowbudgetcleaning/images/pool-garden.jpg");
       }

       a.to-anchor-laundry {
         background-image: url("/sites/all/themes/lowbudgetcleaning/images/dry-cleaning.jpg");
       }

       a.to-anchor-water-dispensers {
         background-image: url("/sites/all/themes/lowbudgetcleaning/images/water-dispenser.jpg");
       }

       a[href="/content/why-choose-us"] {
         background-image: url("/sites/all/themes/lowbudgetcleaning/images/why-choose.jpg");
       }
     }
   }
 }
}