.contact-block {
  color: $action-color;

  text-align: center;

  @include media($large-screen-up) {
    text-align: right;
  }

  @include media($full-screen-up) {
    text-align: left;
  }

  cursor: pointer;
  .icon-phone {

  }
  .telephone-number {
    font-weight: bold;
    font-size: 1.7em;
  }
  .contact-us-today {
    float: none;
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    padding-left: 1em;

    @include media($header-menu-snap) {
      float: none;
      font-size: 1.2em;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      padding-left: 0em;
    }
  }
}

#site-footer .footer-top {
  .block-webform {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 1em 3em;
    border: 1px solid silver;
    background-color: rgba(192, 192, 192, 0.9);
    text-align: center;
    display: none;
    opacity: 0;

    article {
       > h2 {
         font-size: 1.8em;
         line-height: 1;
         margin: 0;
         a {
           color: $medium-gray;
         }
      }

      .field-item {
        > h2 {
          color: $action-color;
          font-size: 1.9em;
          margin: 0;
        }
        > p {
          color: $medium-gray;
        }
      }
    }

    &.open {
      display: block;
      transition: 2s ease all;
      opacity: 1;
    }

    span.close {
      color: white;
      float: right;
      font-weight: bold;
      margin-top: -0.1em;
      cursor: pointer;
      position: absolute;
      right: 1em;
      &:hover {
        color: $action-color;
      }
    }
  }
}


#sidebar-second {
 .contact-block {
   display: none;

   @include media($large-screen-up) {
     display: block;
     margin: 2em 0 5em;
   }
 }
}