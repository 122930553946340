@import "../../../neato/bower_components/neat/app/assets/stylesheets/neat-helpers";
// Neat Overrides

$max-width: em(1088);
$grid-columns: 12 !global;

// Neat Breakpoints
$medium-screen: em(320);
$large-screen: em(860);

$medium-screen-up: new-breakpoint(min-width $medium-screen 4);
$large-screen-up: new-breakpoint(min-width $large-screen 8);
$full-screen-up: new-breakpoint(min-width $max-width 12);

$full-screen-snap-down: new-breakpoint(min-width em(1140) 12);

$header-menu-snap: new-breakpoint(min-width em(522) 4);

$feature-menu-snap-down-small: new-breakpoint(min-width em(522));
$feature-menu-snap-down-large: new-breakpoint(min-width em(865));